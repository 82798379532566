import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress  from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import Typography from '@mui/material/Typography';
import { Button, Container, Divider, TextField } from '@mui/material';
import colors from '../colors'

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as config from '../config'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function BasicTable({rows, deleteMessage}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow key="header">
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Subject</TableCell>
            <TableCell align="left">Message</TableCell>
            <TableCell align="left">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.uuid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">{row.subject}</TableCell>
              <TableCell align="left">{row.message}</TableCell>
              <TableCell align="left"><Button onClick={() => {
                deleteMessage(row.uuid)
              }}>DELETE</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const AdminContactMessage = ({user}) => {
  console.log("user: ", user)
  const [messages, setMessages] = React.useState([])
  React.useEffect(() => {
    if (user.uuid === "") {
      return
    }
    fetch(config.API_URL + "/contact", {
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        console.log("BODY", body)
        let newMessages = []
        for (const [uuid, message] of Object.entries(body)) {
          console.log("ONE ITEM", message)
          newMessages.push(message)
        }
        setMessages(newMessages)
      })
    })
  }, [])

  const deleteMessage = (messageUUID) => {
    fetch(config.API_URL + "/contact/" + messageUUID + "/delete", {
      method: "POST",
      headers: {
        "x-api-key": user.api_key,
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        window.location.replace("/admin/message")
        console.log("BODY", body)
      })
    })
  }

  return (
    <Box>
        <Typography variant="h4">Messages</Typography>
        <Divider></Divider>
        <BasicTable rows={messages} deleteMessage={deleteMessage}></BasicTable>
    </Box>
  );
};
export default AdminContactMessage;