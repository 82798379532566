import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress  from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import Typography from '@mui/material/Typography';
import { Button, Container, Divider, TextField } from '@mui/material';
import colors from '../colors'

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as config from '../config'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { fontStyle } from '@mui/system';


function createData(
  name,
  calories,
  fat,
  carbs,
  protein
) {
  return { name, calories, fat, carbs, protein };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({open, setOpen, user, setUser}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const rotateApiKey = () => {
    fetch(config.API_URL + "/rotateApiKey", {
      method: "POST",
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        let localUser = JSON.parse(localStorage.getItem("user"))
        localUser.api_key = body.api_key
        localStorage.setItem("user", JSON.stringify(localUser))
        console.log(body)
        setUser(localUser)
        window.location.reload()
      })
    })
    
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Rotate your API Key?"}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={rotateApiKey}>Rotate</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SnackBar({open, setOpen, message}) {
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Stack spacing={4} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}


const Account = ({user, setUser}) => {
  const [credits, setCredits] = React.useState(0)
  
  const [apiKey, setApiKey] = React.useState("********************")
  const [apiKeyToggle, setApiKeyToggle] = React.useState(false)
  const [message, setMessage] = React.useState("Successfully copied api key to clipboard")
  const [open, setOpen] = React.useState(false)
  const [rotateOpen, setRotateOpen] = React.useState(false)
  const [updateCardUrl, setUpdateCardUrl] = React.useState("")
  const [cancelCardUrl, setCancelCardUrl] = React.useState("")
  const [userAudit, setUserAudit] = React.useState([])
  const [paddleDisabled, setPaddleDisabled] = React.useState(true)


  React.useEffect(() => {
    if (user.uuid === "") {
      window.location.replace("/login")
      return
    }
    fetch(config.API_URL + "/users/credits", {
      headers: {
        "Authorization": user.session_token
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        console.log("credits response", body)
        setCredits(body.credits)
      })
    })
    fetch(config.API_URL + "/paddleManagement", {
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        console.log(body)
        setUpdateCardUrl(body.update_url)
        setCancelCardUrl(body.cancel_url)
        setPaddleDisabled(false)
      })
    })  }, [])


  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }
  return (
    <Box>
        <AlertDialogSlide open={rotateOpen} setOpen={setRotateOpen} user={user} setUser={setUser}></AlertDialogSlide>
        <Typography variant="h4">Hello {user.email}</Typography>
        <Divider style={{
          marginBottom: '1em'
        }}></Divider>
        <Box style={{
          textAlign: "left"
        }}>
          <Typography>
            Welcome to EmailDetective, you can search for Email addresses directly through the <Link href="/search">website</Link>. 
            If you are interested in using the API please check out the <Link href="/api">documentation</Link>. 
          </Typography>
        </Box>
        <Typography variant="h4" style={{
          marginTop: '1em'
        }}>Remaining Credits</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">{credits}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>      
          </Grid>
        </Grid>

        <Typography variant="h4" style={{
          marginTop: '1em'
        }}>My API key</Typography>
        <Divider style={{
          marginBottom: '1em'
        }}></Divider>
        <Box style={{
          display: "flex"
        }}>
          <TextField fullWidth value={apiKey} />
          <Button onClick={() => {
            copyTextToClipboard(user.api_key)
            setOpen(true)
          }}>Copy</Button>
          <Button onClick={() => {
            if (apiKeyToggle) {
              setApiKey("********************")
              setApiKeyToggle(false)
              return
            }
            setApiKey(user.api_key)
            setApiKeyToggle(true)
          }}>{apiKeyToggle ? "HIDE": "SHOW"} API Key</Button>
          <Button disabled={false} onClick={() => {
            setRotateOpen(true)
          }}>Rotate</Button>
        </Box>
        <SnackBar open={open} setOpen={setOpen} message={message}></SnackBar>
        <Typography variant="h4" style={{
          marginTop: '1em'
        }}>Management</Typography>
        <Divider style={{
          marginBottom: '1em'
        }}></Divider>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Link href={updateCardUrl}>
              <Button disabled={paddleDisabled}>Update Card</Button>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}> 
          <Link href={cancelCardUrl}>
            <Button disabled={paddleDisabled} color="error">Cancel Plan</Button>
            </Link>
          </Grid>
        </Grid>
    </Box>
  );
};
export default Account;