import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
const Logout = ({setUser}) => {
  localStorage.clear()
  window.location.replace("/")
  return (
    <Box>
        <Typography variant="h4">Logging out..</Typography>
    </Box>
  );
};
export default Logout;