import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress  from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import Typography from '@mui/material/Typography';
import { Button, Container, Divider, TextField } from '@mui/material';
import colors from '../colors'

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as config from '../config'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function BasicTable({rows}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow key="header">
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Request Hits</TableCell>
            <TableCell align="left">Request Limit</TableCell>
            <TableCell align="left">Lookup Hits</TableCell>
            <TableCell align="left">Lookup Limit</TableCell>
            <TableCell align="left">Created</TableCell>
            <TableCell align="left">UUID</TableCell>
            <TableCell align="left">Impersonate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.uuid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">{row.request_hits}</TableCell>
              <TableCell align="left">{row.request_limit}</TableCell>
              <TableCell align="left">{row.lookup_hits}</TableCell>
              <TableCell align="left">{row.lookup_limit}</TableCell>
              <TableCell align="left">{row.created_at}</TableCell>
              <TableCell align="left">{row.uuid}</TableCell>
              <TableCell align="left"><a href={`/login?userUUID=${row.uuid}`}>Impersonate</a></TableCell> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const AdminUsers = ({user}) => {
  console.log("user: ", user)
  const [items, setItems] = React.useState([])
  React.useEffect(() => {
    if (user.uuid === "") {
      return
    }
    fetch(config.API_URL + "/user", {
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        console.log("BODY", body)
        let newItems = []
        for (const [uuid, item] of Object.entries(body)) {
          console.log("ONE ITEM", item)
          let newItem = item
          var myDate = new Date(item.created_at*1000);
          newItem.created_at = myDate.toLocaleString()
          newItems.push(newItem)
        }

        // do some type of pre-sort here
        newItems.sort((a,b) => {
          return  b.request_hits - a.request_hits
        })

        setItems(newItems)
      })
    })
  }, [])

  // const deleteMessage = (messageUUID) => {
  //   fetch(config.API_URL + "/contact/" + messageUUID, {
  //     method: "DELETE",
  //     headers: {
  //       "x-api-key": user.api_key,
  //     }
  //   }).then(response => {
  //     console.log(response)
  //     response.json().then(body => {
  //       console.log("BODY", body)
  //     })
  //   })
  // }

  return (
    <Box>
        <Typography variant="h4">Users</Typography>
        <Divider></Divider>
        <BasicTable rows={items}></BasicTable>
    </Box>
  );
};
export default AdminUsers;