import React, {useState} from 'react';

function RegisterForm() {
    const Paddle = window.Paddle;
    const handlePayment = (e) => {
        e.preventDefault();
        Paddle.Checkout.open({
          method: 'inline',
          product: 793977,
          allowQuantity: false,
          disableLogout: true,
          frameInitialHeight: 416,
          frameTarget: 'checkout-container',
          frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;' ,
          // successCallback: (data, err) =>{
          //   console.log("Something with paddle is doing something")
          //     console.log(data);
          //     //ADD YOUR EXTRA LOGIC TO SEND TO BACKEND 
          // }
        });
  };

  return (
  <>
    <form onSubmit={handlePayment}>
      <input type="submit" value="Make Payment"/>
      <div className='checkout-container'></div>
    </form>
  </>
  );
}

export default RegisterForm;