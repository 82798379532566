import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Divider } from '@mui/material';
import colors from "../colors"
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import * as config from '../config'
import TextField from '@mui/material/TextField';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({open, setOpen, children}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button href={`/login`}>Login</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const AlertDeleteMessage= ({open, setOpen, user, message}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this message?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => {
            fetch(config.API_URL + "/contact/" + message.uuid, {
              method: "DELETE",
              headers: {
                "x-api-key": user.api_key
              }
            }).then(response => {
              console.log(response)
              response.json().then(body => {
                console.log(body)
              })
            })
          }} sx={{
            color: colors.RED
          }}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function ContactMessage({user}) {
  const [messages, setMessages] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({})

  React.useEffect(() => {
    fetch(config.API_URL + "/contact", {
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        setMessages(body)
      })
    })
  }, [])
  let children = []
  for (let [uuid, message] of Object.entries(messages)) {
    console.log(`${uuid}: ${message}`);
    children.push((
    <Box style={{
      display: 'flex'
    }}>
      <Box>
        <Typography>From: {message.email}</Typography>
        <Typography>Subject: {message.subject}</Typography>
        <Typography>Message: {message.message}</Typography>
        <Divider></Divider>
      </Box>
      <Button 
        sx={{
            color: colors.RED
        }}
        onClick={() => {
          setOpen(true)
          setMessage(message)
      }}>Delete</Button>
    </Box>))
  }

  return(<Box>
    <AlertDeleteMessage open={open} setOpen={setOpen} user={user} message={message}></AlertDeleteMessage>
    {children}
  </Box>)
}

const SetFormSlide = ({open, setOpen, children}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const [input, setInput] = React.useState({});
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button href={`/login`}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function SetForm({template, input, setInput}) {
  let items = []
  for (let i in template) {
    const fieldTemplate = template[i]
    items.push(<Container>
      <Typography>{fieldTemplate.name}: </Typography>
      <TextField value={fieldTemplate.defaultValue} onChange={(e) => {
        let newInput = input
        newInput[fieldTemplate.id] = e.target.value
        setInput(newInput)
      }}></TextField>
    </Container>)
  }
  return (
  <Box>
    <Box>{items}</Box>
  </Box>)
}

function Users({user, setOpen, setUser, setPopupContent}) {
  const [messages, setMessages] = React.useState({});
  const [input, setInput] = React.useState({});


  React.useEffect(() => {
    fetch(config.API_URL + "/user", {
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        setMessages(body)
      })
    })
  }, [])
  let children = []
  for (let [uuid, user] of Object.entries(messages)) {
    console.log(JSON.stringify(user));
    children.push((
    <Box style={{
      display: 'flex'
    }}>
      <Box>
        <Typography>Email: {user.email}</Typography>
        <Typography>Requests: {user.request_hits} out of {user.request_limit}</Typography>
        <Typography>IP looks ups: {user.lookup_hits} out of {user.request_limit}</Typography>
        <Divider></Divider>
      </Box>
      <Button onClick={() => {
        setPopupContent(<SetForm 
          input={input}
          setInput={setInput}
          template={[
          {
            id: "uuid",
            name: "UUID",
            disabled: true,
            type: "string",
            defaultValue: user.uuid
          },
          {
            id: "email",
            name: "Email",
            disabled: false,
            type: "string",
            defaultValue: user.email
          },
          {
            id: "request_hits",
            name: "Request Hits",
            disabled: false,
            type: "int",
            defaultValue: user.request_hits
          },
          {
            id: "request_limit",
            name: "Request Limits",
            disabled: false,
            type: "int",
            defaultValue: user.request_limit
          },
          {
            id: "lookup_hits",
            name: "Lookup Hits",
            disabled: false,
            type: "int",
            defaultValue: user.lookup_hits
          },
          {
            id: "lookup_limit",
            name: "Lookup Limits",
            disabled: false,
            type: "int",
            defaultValue: user.lookup_limit
          }
        ]}
          ></SetForm>)
        setOpen(true)
        setUser(user)
      }}>UPDATE</Button>
    </Box>
    ))
  }

  return(<Box>
    {children}
  </Box>)
}

const Admin = ({user}) => {
  const [open, setOpen] = React.useState(false)
  const [selectedUser, setUser] = React.useState(false)
  const [popupContent, setPopupContent] = React.useState(<div></div>)
  return (
    <Box style={{
      textAlign: 'left'
    }}>
        <SetFormSlide open={open} setOpen={setOpen} setPopupContent={setPopupContent}></SetFormSlide>
        {/* <Typography variant="h2">Messages</Typography>
        <Divider></Divider>
        <ContactMessage user={user}></ContactMessage> */}
        <Typography variant="h2">Users</Typography>
        <Users user={user} setUser={setUser} setOpen={setOpen} setPopupContent={setPopupContent}></Users>
    </Box>
  );
};
export default Admin;