import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress  from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import Typography from '@mui/material/Typography';
import { Button, Container, Divider, TextField } from '@mui/material';
import colors from '../colors'

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as config from '../config'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function BasicTable({rows}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow key="header">
            <TableCell>Time ago</TableCell>
            <TableCell>User UUID</TableCell>
            <TableCell align="right">Action</TableCell>
            <TableCell align="right">IP Address</TableCell>
            <TableCell align="right">Bot</TableCell>
            <TableCell align="right">Error</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">ASN</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Country Code</TableCell>
            <TableCell align="right">Country Name</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.uuid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{row.time_ago}</TableCell>
              <TableCell align="right">{row.user_uuid}</TableCell>
              <TableCell align="right">{row.action}</TableCell>
              <TableCell align="right">{row.ip}</TableCell>
              <TableCell align="right">{JSON.stringify(row.bot)}</TableCell>
              <TableCell align="right">{row.error}</TableCell>
              <TableCell align="right">{row.type}</TableCell>
              <TableCell align="right">{row.asn}</TableCell>
              <TableCell align="right">{row.asn_description}</TableCell>
              <TableCell align="right">{row.country_code}</TableCell>
              <TableCell align="right">{row.country_name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const AdminAudit = ({user}) => {
  console.log("user: ", user)
  const [audit, setAudit] = React.useState([])
  React.useEffect(() => {
    if (user.uuid === "") {
      return
    }
    fetch(config.API_URL + "/audit?info=true", {
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        console.log(body)
        const now = Math.floor(Date.now() / 1000)
        console.log("time ago: ", now)
        let reversedAudit = []
        let plural = ""
        for (let row of body) {
          let timeSpan = "second"
          let timeAgo = now - row.created_at
          if (timeAgo > 86400) {
            timeSpan = "day"
            timeAgo = ~~(timeAgo/86400)
          } else if (timeAgo > 3600) {
            timeSpan = "hour"
            timeAgo = ~~(timeAgo/3600)
          } else if (timeAgo > 60) {
            timeSpan = "minute"
            timeAgo = ~~(timeAgo/60)
          }
          if (Number(timeAgo) > 1) {
            plural = "s"
          }
          row.time_ago = `${timeAgo} ${timeSpan}${plural} ago`
          reversedAudit.push(row)
        }
        setAudit(reversedAudit)
      })
    })
  }, [])
  return (
    <Box>
        <Typography variant="h4">Audit</Typography>
        <Divider></Divider>
        <BasicTable rows={audit}></BasicTable>
    </Box>
  );
};
export default AdminAudit;