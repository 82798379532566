import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Chip } from '@mui/material';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider'

const theme = createTheme();

export default function SignIn({user, config}) {
  const [email, setEmail] = React.useState("")
  const [severity, setSeverity] = React.useState("info")
  const [alertMessage, setAlertMessage] = React.useState("")
  const pageConfig = config.PAGE_CONFIG.login

  const handleLoginEmail = () => {
    setEmail("")

    // determine login type
    if (email.includes("@")) {
      fetch(config.API_URL + "/users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "type": "email",
          "value": email,
        })
      }).then(response => {
        response.json().then(body => {
          if (response.status !== 200) {
            setSeverity('error')
            setAlertMessage(body.message)
            return
          }
  
          setSeverity('success')
          setAlertMessage("Please check your email to log into EmailDetective")
        })
      })
    } else {
      // api key authentication - kinda hacky
      const apiKey = email 
      fetch(config.API_URL + "/users", {
        method: "POST",
        body: JSON.stringify({
          "api_key": apiKey,
          "login_type": "apiKey",
        })
      }).then(response => {
        response.json().then(body => {
          if (response.status !== 200) {
            setSeverity('error')
            setAlertMessage(body.message)
            return
          }

          // redirect to authentication url
          setSeverity('success')
          setAlertMessage("Successfully logged in - redirecting")
          console.log("redirecting to " + body.redirect_url)
          document.location.replace(body.redirect_url)
        })
      })
    }
  }


  const handleLoginGoogle = (credentialResponse) => {
    setEmail("")
    fetch(config.API_URL + "/users", {
      method: "POST",
      headers: {
        "Authorization": credentialResponse.credential,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "value": email,
        "type": "google"
      })
    }).then(response => {
      response.json().then(body => {
        if ('error' in body && body.error !== "") {
          setSeverity('error')
          setAlertMessage(body.error)
          return
        }

        setSeverity('success')
        setAlertMessage("Successfully logged in - redirecting")
        console.log("redirecting to " + body.message)
        document.location.replace(body.message)
      })
    })
  }

  React.useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
  
    if (params.userUUID !== undefined && params.userUUID !== null && params.userUUID !== "") {
      fetch(config.API_URL + "/getApiKey?userUUID=" + params.userUUID, {
        method: "GET",
        headers: {
          "x-api-key": user.api_key,
        },
      }).then(response => {
        response.json().then(body => {
          if ('error' in body && body.error !== "") {
            setSeverity('error')
            setAlertMessage(body.error)
            return
          }
  
          setSeverity('success')
          setAlertMessage("Successfully impersonating")
          
          const apiKey = body.api_key 
          fetch(config.API_URL + "/users", {
            method: "POST",
            body: JSON.stringify({
              "api_key": apiKey,
              "login_type": "apiKey",
            })
          }).then(response => {
            response.json().then(body => {
              if ('error' in body && body.error !== "") {
                setSeverity('error')
                setAlertMessage(body.error)
                return
              }
    
              // redirect to authentication url
              setSeverity('success')
              setAlertMessage("Successfully logged in - redirecting")
              console.log("redirecting to " + body.redirect_url)
              document.location.replace(body.redirect_url)
            })
          })
          return
        })
      })
    } else {
      if (user !== undefined && user.email !== "") {
        window.location.replace("/account")
      }
    }
  }, [])



  return (
    <GoogleOAuthProvider clientId="865930048182-lbkuogvidlp62bnm7b6m1a249o9ing29.apps.googleusercontent.com">
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Typography variant='subtitle2'>
            {pageConfig.subtitle}
          </Typography> 
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address or API key"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
            { severity !== 'info' &&
              <Alert style={{
                marginTop: '1em'
              }} severity={severity}>{alertMessage}</Alert>
            }
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLoginEmail}
            >
              Sign In
            </Button>
            <Divider style={{
              marginTop: '1em'
            }}>
              <Chip label="or" style={{
              }}></Chip>
            </Divider>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              style={{ 
                minHeight: '100vh',
                marginTop: '2em'
              }}
            >
              <Grid item xs={3}>
                <Box>
                  <GoogleLogin
                    onSuccess={handleLoginGoogle}
                    onError={() => {
                      setSeverity('error')
                      setAlertMessage('Google login failed')
                    }}
                  />
                </Box>          
              </Grid>   
            </Grid> 
          </Box>
        </Box>
      </Container>
    </GoogleOAuthProvider>
  );
}