import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { Divider, Grid,  Paper} from '@mui/material';
import SignIn from './NewLogin';

import React from 'react';
import Carousel from 'react-material-ui-carousel';
import HomeSearchEmail from './HomeSearchEmail';
import HomeSignIn from './HomeSignIn';

function CustomersCarosel({customers})
{
  if (customers == null || customers == undefined || customers.length === 0) {
    return (<div></div>)
  }
    return (
        <Carousel autoPlay={true} interval={2500} stopAutoPlayOnHover={false}>
            {
                customers.map( (customer, i) => {
                  const nextI = i + 1;
                  // if (nextI == customers.length) {
                  //   return
                  // }
                  return (<Box style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'}}>
                  <Item key={i} customer={customer} />
                  {/* {customers.length > nextI &&
                    (<Item key={nextI} customer={customers[nextI]} />)

                  } */}
                </Box>)} )
            }
        </Carousel>
    )
}

function Item(customer)
{
  customer = customer.customer
    return (
        <Paper>
            <img style={{backgroundColor: customer.background, marginLeft: '1em', marginRight: '1em'}} src={customer.image_path} width={customer.width} height={customer.height} alt={customer.title}></img>          
        </Paper>
    )
}

const Home = ({config, user}) => {
  const handleSeePricing = () => {
    window.location.replace("/pricing")
  }
  const handleSeeApiDocs = () => {
    window.location.replace("/api")
  }
  const handleSeeSearch = () => {
    window.location.replace("/search")
  }
  return (
    <Box>
        <Box sx={{
          display: {md: 'none'}
        }}>
          <config.DEFAULT_LOGO
            width={350}
            height={120}
            color={config.PRIMARY_COLOR}
          ></config.DEFAULT_LOGO>
        </Box>
        <Box sx={{
          display: {xs: 'none', md: 'inline-flex'}
        }}>
          <config.DEFAULT_LOGO
            width={500}
            height={150}
            color={config.PRIMARY_COLOR}
          ></config.DEFAULT_LOGO>
        </Box>
        
        <config.PAGE_CONFIG.home.summary></config.PAGE_CONFIG.home.summary>
        <br></br>
        
        <HomeSearchEmail user={user}></HomeSearchEmail>
        <br></br>
        <Typography variant="h4" style={{marginTop: '.5em'}}>Free</Typography>
        <Typography>{config.PAGE_CONFIG.home.free_summary}</Typography>
        <Button variant="contained" style={{marginTop: '.75em', marginBottom: '1em'}} onClick={handleSeePricing}>See Pricing</Button>
        <Typography variant="h4" style={{marginTop: '.5em'}}>Fast</Typography>
        <Typography>{config.PAGE_CONFIG.home.fast_summary}</Typography>
        <Button variant="contained" style={{marginTop: '.75em', marginBottom: '1em'}} onClick={handleSeeSearch}>Try it out</Button>
        <Typography variant="h4" style={{marginTop: '.5em'}}>Simple</Typography>
        <Typography>{config.PAGE_CONFIG.home.simple_summary}</Typography>
        <Button variant="contained" style={{marginTop: '.75em', marginBottom: '1em'}} onClick={handleSeeApiDocs}>See API Docs</Button>
        <br></br><br></br>
        <HomeSignIn config={config}></HomeSignIn>
        {/* <Typography variant="h4" style={{marginTop: '.5em', marginBottom: '.5em'}}>Customers & Partners</Typography> */}
        {/* <Box style={{
          display: "flex"
        }}> */}
        {/* <CustomersCarosel customers={config.PAGE_CONFIG.home.customers}></CustomersCarosel> */}
          {/* <Grid container spacing={2} >
          {config.PAGE_CONFIG.home.customers.map(customer => (
            <Grid item xs={12} md={4}>
                <img style={{backgroundColor: customer.background}} src={customer.image_path} width={customer.width} height={customer.height} alt={customer.title}></img>          
            </Grid>))}
          </Grid> */}
        {/* </Box> */}
        <br></br><br></br>
        <Typography variant="h4" style={{marginTop: '.5em'}}>About Us</Typography>
        <Typography>{config.PAGE_CONFIG.home.about_us_summary}</Typography>
    </Box>
  );
};
export default Home;