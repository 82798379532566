import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material';

const Code = ({children}) => {
    console.log(children)
    return (
      <SyntaxHighlighter useInlineStyles={true} language="go" style={docco}>
        {children}
      </SyntaxHighlighter>
    )
}

const code = `import (
	"context"
	"log"
	"os"

	"github.com/AndrewCopeland/go-emaildetective"
)

func main() {
        // initialize your client - make sure to set your environment variable
	cfg := emaildetective.NewConfiguration()
	cfg.AddDefaultHeader("Authorization", os.Getenv("EMAILDETECTIVE_TOKEN"))
	client := emaildetective.NewAPIClient(cfg).EmailInfoAPI
	ctx := context.Background()

        // perform email validation for 'example@emaildetective1.io'
	resp, httpResp, err := client.GetEmailAddressInfo(ctx, "example@emaildetective1.io").Execute()
	if err != nil {
		log.Fatalf("could not get email address info. %s", err)
	}
	if httpResp.StatusCode != 200 {
		log.Fatal("invalid status code returned, make sure authorization token is correct")
	}
	fmt.Println("Email: ", resp.Email)
	fmt.Println("User: ", resp.User)
	fmt.Println("Domain: ", resp.Domain)
	fmt.Println("ValidEmail: ", resp.ValidEmail)
	fmt.Println("ValidSpf: ", resp.ValidSpf)
	fmt.Println("ValidDmarc: ", resp.ValidDmarc)
	fmt.Println("ValidMx: ", resp.ValidMx)
	fmt.Println("ValidTld: ", resp.ValidTld)
	fmt.Println("Nonsense: ", resp.Nonsense)
	fmt.Println("Role: ", resp.Role)
	fmt.Println("Free: ", resp.Free)
	fmt.Println("Disposable: ", resp.Disposable)
	fmt.Println("Score: ", resp.Score)
	fmt.Println("SuspicionRating: ", resp.SuspicionRating)
}`

export default function GoClientLibraryEmailDetective() {
    return (<Box>
        <Typography>
            First thing first make sure to install the go package
        </Typography>
        <Code>
            go get github.com/AndrewCopeland/go-emaildetective
        </Code>
        <Typography>
            Below is a small go program that will perform email validation and return all of the validation information for 'example@emaildetective1.io' and determines a score and suspicion rating.
        </Typography>
        <Code>
            {code}
        </Code>
        <Typography>
            This should get your started with EmailDetective. If you are interested in the other endpoints like <Link href="https://github.com/AndrewCopeland/go-emaildetective/blob/main/docs/EmailInfoAPI.md#getbulkemailaddressinfo">bulk email validation</Link> or <Link href="https://github.com/AndrewCopeland/go-emaildetective/blob/main/docs/EmailInfoAPI.md#getemailcsv">CSV email validation</Link> take a look at the <Link href="https://github.com/AndrewCopeland/go-emaildetective">Github</Link>.
        </Typography>
    </Box>)
}