import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Chip, Divider } from '@mui/material';

const tags = {
  "new": <Chip label="New" color="primary" />,
  "improvement": <Chip label="Improvement" color="primary" style={{backgroundColor:'#b26cee'}}/>,
  "fix": <Chip label="Fix" color="primary" style={{backgroundColor:'#ff4772'}}/>
}

const newChangeLogComponents = (pageConfig) => {
  let result = []
  for (let i = 0; i < pageConfig.change_logs.length; i++) {
    const changeLog = pageConfig.change_logs[i]
    let paragraphs = []
    for (let j = 0; j < changeLog.paragraphs.length; j++) {
      const para = changeLog.paragraphs[j]
      paragraphs.push(<Typography align="left" style={{
        marginBottom: '1em'
      }}>{para}</Typography>)
    }
    result.push(<Box>
        <Typography variant="h5" align='left'>{changeLog.title}</Typography>
        <Typography variant="subtitle2" align='left' style={{
          marginBottom: '.75em'
        }}>{changeLog.date}</Typography>
        <Typography align="left" color="#FFFFFF" style={{
          marginBottom: '1em'
        }}>{tags[changeLog.tag]}</Typography>
        
        {paragraphs}
        <Divider style={{
          marginBottom: '1em'
        }}></Divider>
    </Box>)
  }
  return result
}

const ChangeLogs = ({pageConfig}) => {
  const changeLogComponents = newChangeLogComponents(pageConfig)
  return (
    <Container maxWidth="sm">
        <Typography variant="h4" style={{
          marginBottom: '1em'
        }}>Changelog</Typography>
        {changeLogComponents}
    </Container>
  );
};
export default ChangeLogs;