import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useParams } from 'react-router-dom'


const Blog = ({pageConfig}) => {
    const blogPostMapping = pageConfig.blogs
    let blogPostList = []
    for (let [id, blogPost] of Object.entries(blogPostMapping)) {
        blogPostList.push(blogPost)
    }

    return (
        <Box style={{
        textAlign: 'left',
        alignContent: 'left'
        }}>
            
            {blogPostList.map(blogPost => (
                <Box style={{
                    marginBottom: '1em'
                }} key={blogPost.id}> 
                    <Divider style={{
                        marginBottom: '1em'
                    }}/>
                    <Box style={{
                        display: "flex"
                    }}>
                        <Box style={{
                            width: "130px",
                            minWidth: "130px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Link href={"blog/" + blogPost.id}>
                                <img src={blogPost.image} width={blogPost.width} height={blogPost.height} alt={blogPost.title}></img>
                            </Link>
                        </Box>
                        <Box style={{
                            marginLeft: '1em'
                        }}>
                            <Link href={"blog/" + blogPost.id}><Typography variant="h5">{blogPost.title}</Typography></Link>
                            <Typography variant='subtitle2' style={{
                                marginTop: '.12em'
                            }}>{blogPost.date}</Typography>
                            <Typography style={{
                                marginTop: '.5em'
                            }}>{blogPost.subTitle}</Typography>
                        </Box>
                    </Box>
                </Box>
            ))}
            <Divider/>
        </Box>
    );
};

const BlogRouter = ({pageConfig}) => {
    const blogPostMapping = pageConfig.blogs
    console.log(useParams())
    const blogPost = blogPostMapping[useParams().id]
    return (<Box style={{
        textAlign: 'left',
        alignContent: 'left'
        }}>
        <Typography style={{
        }} variant="h4">{blogPost.title}</Typography>
        <Typography style={{
            marginTop: '.12em',
            marginBottom: '1em'
        }} variant="subtitle1">{blogPost.date}</Typography>
        {/* <img src={blogPost.image} width={blogPost.width} height={blogPost.height} alt={blogPost.title}></img> */}
        {blogPost.body}
    </Box>)
}

export {
    Blog,
    BlogRouter,
}