import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
export default function FAQ({pageConfig}) {


  return (
    <Box>
      <Typography variant="h4">FAQ</Typography>
      <Box  style={{
        textAlign: 'left'
      }}>
        {pageConfig.map((faq, i) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${i}a-content`}
              id={`panel${i}a-header`}
            >
              <Typography>{faq.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <faq.info></faq.info>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}