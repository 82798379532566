import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Alert, Link, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import * as config from '../config'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Code = ({children}) => {
  console.log(children)
  return (
    <SyntaxHighlighter language="json" style={docco}>
      {children}
    </SyntaxHighlighter>
  )
}

const SearchResult = ({searchResult, setReportOpen}) => {
  console.log(searchResult)
  const bold = {
    fontWeight: 'bold'
  }
  const handleOpen = () => {
    console.log("Should open report")
    setReportOpen(true)
  }
  return (
    <Box textAlign='left' width='400px'>
        <Code>
          {JSON.stringify(searchResult, null, 2)}
        </Code>
        {/* <Link onClick={handleOpen} style={{
          cursor: 'pointer'
        }}><Typography>Report inaccuracies</Typography></Link> */}
    </Box>
  )
}

const AlertDialogSlide = ({open, setOpen}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Please login"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Log in with your email to EmailDetective and access 1,000 FREE email validation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button href={`/login`}>Login</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ReportDialogSlide = ({open, setReportOpen, searchResult, user, ip}) => {
  const [openASNTextField, setOpenASNTextField] = React.useState(false)
  const [openCountryextField, setOpenCountryTextField] = React.useState(false)
  const [openAdditionalTextField, setOpenAdditionalTextField] = React.useState(false)
  const [severity, setSeverity] = React.useState('info')
  const [alertMessage, setAlertMessage] = React.useState("")
  const [isABot, setIsABot] = React.useState(false)
  const [isNotABot, setIsNotABot] = React.useState(false)
  const [incorrectASN, setIncorrectASN] = React.useState(false)
  const [incorrectASNText, setIncorrectASNText] = React.useState(false)
  const [incorrectCountry, setIncorrectCountry] = React.useState(false)
  const [incorrectCountryText, setIncorrectCountryText] = React.useState(false)
  const [additionalText, setAdditionalText] = React.useState(false)
  const [additionalTextText, setAdditionalTextText] = React.useState(false)
  const [internalIP, setInternalIP] = React.useState(ip)

  React.useEffect(() => {
    setInternalIP(ip)
  }, [ip])

  const resetValues = () => {
    setOpenASNTextField(false)
    setOpenCountryTextField(false)
    setOpenAdditionalTextField(false)
    setIsABot(false)
    setIsNotABot(false)
    setIncorrectASN(false)
    setIncorrectASNText(false)
    setIncorrectCountry(false)
    setIncorrectCountryText(false)
    setAdditionalText(false)
    setAdditionalTextText(false)
  }

  const handleIncorrectASNToggle = () => {
    setOpenASNTextField(!openASNTextField)
  }

  const handleIncorrectCountryToggle = () => {
    setOpenCountryTextField(!openCountryextField)
  }

  const handleAdditionalTextFieldToggle = () => {
    setOpenAdditionalTextField(!openAdditionalTextField)
  }
  const handleClose = () => {
    setReportOpen(false);
  };
  const handleSubmit = () => {
    setSeverity('success')
    setAlertMessage('Sending...')
   
    const message = `
IP: ${ip}
Is a bot: ${isABot}
Is not a bot: ${isNotABot}
Incorrect ASN: ${incorrectASN}
Incorrect ASN Value: ${incorrectASNText}
Incorrect Country: ${incorrectCountry}
Incorrect Country Value: ${incorrectCountryText}
Additional information: ${additionalText}
Additional information Value: ${additionalTextText}`

    fetch(config.API_URL + "/contact", {
      method: "POST",
      body: JSON.stringify({
        subject: "inaccuracies",
        email: user.email,
        message: message
      })
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        console.log("meep", body)
        setTimeout(function () {
          setSeverity('info')
          handleClose()
        }, 750);
        if ('error' in body) {
          setAlertMessage(body.error)
          setSeverity('error')
          return
        }

        setAlertMessage("Sent!")
        setSeverity('success')
        resetValues()
      })
    })

    

  }

  const setFormValue = (type, setFunc, preProcess=function(){}) => {
    return (e) => {
      preProcess()
      if (type === "bool") {
        setFunc(e.target.checked)
      } else if (type === "string") {
        setFunc(e.target.value)
      }
      
    }
  }
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle><Typography variant="h6">Report an IP address</Typography></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography sx={{ fontWeight: 'bold', marginBottom: '.5em' }}>{ip}</Typography>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={isABot} onChange={setFormValue("bool", setIsABot)}/>} label="Is bot" />
              <FormControlLabel control={<Checkbox checked={isNotABot} onChange={setFormValue("bool", setIsNotABot)}/>} label="Is not bot" />
              <FormControlLabel control={<Checkbox checked={incorrectASN} onChange={setFormValue("bool", setIncorrectASN, handleIncorrectASNToggle)}/>} label="Incorrect ASN" />
              {  openASNTextField && 
                <FormControlLabel control={<TextField onChange={setFormValue("string", setIncorrectASNText)} style={{
                  marginLeft: '2.5em',
                  width: '8em'
                }}/>} label="" />
              }
              <FormControlLabel control={<Checkbox checked={incorrectCountry} onChange={setFormValue("bool", setIncorrectCountry, handleIncorrectCountryToggle)}/>} label="Incorrect Country" />
              {  openCountryextField && 
                <FormControlLabel control={<TextField onChange={setFormValue("string", setIncorrectCountryText)} style={{
                  marginLeft: '2.5em',
                  width: '8em'
                }}/>} label="" />
              }
              <FormControlLabel control={<Checkbox checked={additionalText} onChange={setFormValue("bool", setAdditionalText, handleAdditionalTextFieldToggle)}/>} label="Additional information" />
              {  openAdditionalTextField && 
                <FormControlLabel control={<TextField onChange={setFormValue("string", setAdditionalTextText)} style={{
                  marginLeft: '2.5em',
                  width: '8em'
                }}/>} label="" />
              }
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box>
          { severity !== 'info' &&
              <Alert style={{
                marginTop: '1em'
              }} severity={severity}>{alertMessage}</Alert>
          }
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const SearchEmail = ({user}) => {
  const [open, setOpen] = React.useState(false)
  const [reportOpen, setReportOpen] = React.useState(false)
  const [alertVariant, setAlertVariant] = React.useState(null)
  const [alertMessage, setAlertMessage] = React.useState("")
  const [searchResult, setSearchResult] = React.useState({}) 

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const [email, setEmail] = React.useState(params.email)

  React.useEffect(() => {
    if (params.email === null) {
      return
    }
    setEmail(email)
    let endpoint="/ui/emails"
    if (user != undefined && user.session_token != undefined) {
      endpoint = "/emails"
    }
    fetch(config.API_URL + `${endpoint}/${params.email}`, {
      headers: {
        "Authorization": user.session_token,
        "Content-Type": "applicatin/json",
        "Accept": "application/json"
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        if ('error' in body) {
          console.log(body)
          setOpen(true)
          return
        }
        document.getElementById("search-email").value = body.email
        if (body.bot) {
          setAlertVariant('error')
          setAlertMessage(<SearchResult email={email} searchResult={body} setReportOpen={setReportOpen}></SearchResult>)
        } else {
          setAlertVariant('success')
          setEmail(body.email)
          setSearchResult(body)
          setAlertMessage(<SearchResult email={email} searchResult={body} setReportOpen={setReportOpen}></SearchResult>)
        }
      })
    })
  }, [])

  return (
    <Box>
        <AlertDialogSlide open={open} setOpen={setOpen}></AlertDialogSlide>
        { email !== null &&
          <ReportDialogSlide open={reportOpen} setReportOpen={setReportOpen} searchResult={searchResult} user={user} email={email}></ReportDialogSlide>
        }
        <Typography variant="h4">Email lookup</Typography>
        <Container style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Box style={{
            display:'inline-flex',
            marginTop: '1em'
          }}>
            <TextField
              label="Email"
              id="search-email"
              style={{ width: '300px' }}
              defaultValue={"example@emaildetective1.io"}
            ></TextField>
            <Button
              variant="contained"
              onClick={() => {
                const email = document.getElementById("search-email").value
                setEmail(email)
                window.location.replace("/search-email?email="+email)
              }}
            >Search</Button>
          </Box>
          <Box style={{
            marginTop: '1em',
          }}>
            { alertVariant !== null &&
              <Box>{alertMessage}</Box>
            }
          </Box>
        </Container>

    </Box>
  );
};
export default SearchEmail;