import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import * as config from '../config'

const Contact = ({user, pageConfig}) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const [department, setDepartment] = React.useState(params.subject);
  const [email, setEmail] = React.useState(user.email)
  console.log(params.message)
  const [message, setMessage] = React.useState(params.message)
  const [severity, setSeverity] = React.useState('info')
  const [alertMessage, setAlertMessage] = React.useState("")
  const contactSubjects = pageConfig.contact_subjects

  const handleSubjectChange = (e) => {
    console.log(e.target.value)
    setDepartment(e.target.value)
  }
  return (
    <Box>
        <Typography variant="h4">Get in Contact</Typography>
        <FormControl fullWidth>
          <Typography style={{
            marginTop: '1em',
            textAlign: 'left'
          }}>Subject</Typography>
          <Select
            id="contact-subject"
            value={department}
            onChange={handleSubjectChange}
          >
            {contactSubjects.map((subject) => (
              <MenuItem value={subject.value}>{subject.text}</MenuItem>
            ))}
          </Select>
          <Typography style={{
            marginTop: '1em',
            textAlign: 'left'
          }}>Email</Typography>
          <TextField
            id="contact-email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          ></TextField>
          <Typography style={{
            marginTop: '1em',
            textAlign: 'left'
          }}>Message</Typography>
          <TextField
            id="contact-message"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value)
            }}
          ></TextField>
      </FormControl>
      { severity !== 'info' &&
            <Alert style={{
              marginTop: '1em'
            }} severity={severity}>{alertMessage}</Alert>
      }
      <Button 
      variant="contained" style={{marginTop: '.75em', marginBottom: '1em'}}
      onClick={() => {
        console.log("subject: ", department)
        console.log("email: ", email)
        console.log("message: ", message)

        fetch(config.API_URL + "/contact", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            subject: department,
            email: email,
            message: message
          })
        }).then(response => {
          console.log(response)
          response.json().then(body => {
            console.log("meep", body)
            setDepartment("")
            setEmail("")
            setMessage("")
            if (body.status_code !== 200) {
              setAlertMessage(body.message)
              setSeverity('error')
              return
            }

            setAlertMessage("Contact message has been sucessfully sent")
            setSeverity('success')
          })
        })

      }}>Submit</Button>
      <Typography style={{
            marginTop: '2em'
          }}>Please allow up to 48 hours for a reply. If you have trouble using the form feel free to reach out on discord.</Typography>
    </Box>
  );
};
export default Contact;