import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Alert, Link, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import * as config from '../config'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Code = ({children}) => {
  console.log(children)
  return (
    <SyntaxHighlighter language="json" style={docco}>
      {children}
    </SyntaxHighlighter>
  )
}

const SearchResult = ({searchResult, setReportOpen}) => {
  console.log(searchResult)
  const bold = {
    fontWeight: 'bold'
  }
  const handleOpen = () => {
    console.log("Should open report")
    setReportOpen(true)
  }
  return (
    <Box textAlign='left' width='400px'>
        <Code>
          {JSON.stringify(searchResult, null, 2)}
        </Code>
        {/* <Link onClick={handleOpen} style={{
          cursor: 'pointer'
        }}><Typography>Report inaccuracies</Typography></Link> */}
    </Box>
  )
}

const AlertDialogSlide = ({open, setOpen}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Please login"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Log in with your email to EmailDetective and access 1,000 FREE email validations.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button href={`/login`}>Login</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const HomeSearchEmail = ({user}) => {
  const [open, setOpen] = React.useState(false)
  const [reportOpen, setReportOpen] = React.useState(false)
  const [alertVariant, setAlertVariant] = React.useState(null)
  const [alertMessage, setAlertMessage] = React.useState("")
  const [searchResult, setSearchResult] = React.useState({}) 

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const [email, setEmail] = React.useState(params.email)

  return (
    <Box>
        <AlertDialogSlide open={open} setOpen={setOpen}></AlertDialogSlide>
        <Typography variant="h4">Try it out</Typography>
        <Container style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Box style={{
            display:'inline-flex',
            marginTop: '1em'
          }}>
            <TextField
              label="Email"
              id="search-email"
              style={{ width: '300px' }}
              defaultValue={"example@emaildetective1.io"}
            ></TextField>
            <Button
              variant="contained"
              onClick={() => {
                const email = document.getElementById("search-email").value
                setEmail(email)
                let endpoint="/ui/emails"
                if (user != undefined && user.session_token != undefined) {
                  endpoint = "/emails"
                }
                fetch(config.API_URL + `${endpoint}/${email}`, {
                  headers: {
                    "Content-Type": "applicatin/json",
                    "Accept": "application/json",
                    "Authorization": user.session_token,
                  }
                }).then(response => {
                  // console.log(response)
                  response.json().then(body => {
                    if ('error' in body) {
                      // console.log(body)
                      setOpen(true)
                      return
                    }
                    document.getElementById("search-email").value = body.email
                    if (body.bot) {
                      setAlertVariant('error')
                      setAlertMessage(<SearchResult email={email} searchResult={body} setReportOpen={setReportOpen}></SearchResult>)
                    } else {
                      setAlertVariant('success')
                      setEmail(body.email)
                      setSearchResult(body)
                      setAlertMessage(<SearchResult email={email} searchResult={body} setReportOpen={setReportOpen}></SearchResult>)
                    }
                  })
                })
              }}
            >Search</Button>
          </Box>
          <Box style={{
            marginTop: '1em',
          }}>
            { alertVariant !== null &&
              <Box>{alertMessage}</Box>
            }
          </Box>
        </Container>
    </Box>
  );
};
export default HomeSearchEmail;